<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Reference Bank</span>
      </v-card-title>
      <v-card-text>
        <v-text-field flat outlined dense v-model="referenceBank"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close">
          Cancel
        </v-btn>
        <v-btn color="success" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "form-reference-bank",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      referenceBank: "",
    };
  },
  methods: {
    save() {
      this.$emit("save", this.referenceBank);
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
