<template>
  <div>
    <v-card outlined elevation="2" class="my-2">
      <v-card-title>General</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="5" lg="3">
            <v-autocomplete
              outlined
              flat
              dense
              append-icon="mdi-magnify"
              :items="paymentGeneralKasbons"
              item-text="voucherNumber"
              item-value="id"
              v-model="form.kasbons"
              multiple
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="3">
            <v-autocomplete
              outlined
              flat
              dense
              append-icon="mdi-magnify"
              :items="paymentSettlementKasbons"
              item-text="voucherNumber"
              item-value="id"
              v-model="form.settlements"
              multiple
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="3">
            <v-btn color="success" @click="openDialog('general')">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- <v-card outlined elevation="2" class="my-2">
      <v-card-title>Installer</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="5" lg="3">
            <v-autocomplete
              outlined
              flat
              dense
              append-icon="mdi-magnify"
              :items="kasbonExternals"
              item-text="voucherNumber"
              item-value="id"
              v-model="form.kasbonId"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="3">
            <v-autocomplete
              outlined
              flat
              dense
              append-icon="mdi-magnify"
              :items="settlementExternals"
              item-text="voucherNumber"
              item-value="id"
              v-model="form.settlementId"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="3">
            <v-btn color="success" @click="openDialog('installer')">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card outlined elevation="2" class="mx-y">
      <v-card-title>Driver</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="5" lg="3">
            <v-autocomplete
              outlined
              flat
              dense
              append-icon="mdi-magnify"
              :items="paymentDriverKasbons"
              item-text="voucherNumber"
              item-value="id"
              v-model="form.kasbonId"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="3">
            <v-autocomplete
              outlined
              flat
              dense
              append-icon="mdi-magnify"
              :items="paymentSettlementDriverKasbons"
              item-text="voucherNumber"
              item-value="id"
              v-model="form.settlementId"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="3">
            <v-btn color="success" @click="openDialog('driver')">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->
    <form-reference-bank :dialog="dialog" @close="close" @save="save"></form-reference-bank>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormReferenceBank from "./FormReferenceBankV2.vue";

export default {
  name: "create-payment-kasbon-combination",
  components: {
    FormReferenceBank,
  },
  computed: {
    ...mapState("paymentGeneralKasbon", ["paymentGeneralKasbons"]),
    ...mapState("paymentSettlementKasbon", ["paymentSettlementKasbons"]),
    ...mapState("paymentDriverKasbon", ["paymentDriverKasbons"]),
    ...mapState("paymentSettlementDriver", ["paymentSettlementDriverKasbons"]),
    ...mapState("kasbonExternal", ["kasbonExternals"]),
    ...mapState("settlementExternal", ["settlementExternals"]),
    ...mapState("combinationKasbon", ["lastFormNumber"]),
  },
  data() {
    return {
      defaultForm: {
        referenceBank: "",
        kasbons: [],
        settlements: [],
        type: "",
        formNumber: "",
      },
      form: {},
      dialog: false,
    };
  },
  methods: {
    async save(referenceBank) {
      this.form.referenceBank = referenceBank;
      this.form.formNumber = this.lastFormNumber;
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("combinationKasbon/create", this.form)
        .then(() => {
          this.reset();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async openDialog(type) {
      await this.$store.dispatch("combinationKasbon/getLastFormNumber");
      this.form.type = type;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    reset() {
      this.form = Object.assign({}, this.defaultForm);
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("paymentGeneralKasbon/getAll"),
        await this.$store.dispatch("paymentSettlementKasbon/getAll"),
        await this.$store.dispatch("paymentDriverKasbon/getAll"),
        await this.$store.dispatch("paymentSettlementDriver/getAll"),
        await this.$store.dispatch("kasbonExternal/getAll"),
        await this.$store.dispatch("settlementExternal/getAll"),
        this.reset(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
